import commonSettings from "@common/settings/intus";
import administrationMenu from "./menus/administration.intus";
import extranetMenu from "./menus/extranet.intus";
import administrationFooter from "./footer/administration.intus";
import extranetFooter from "./footer/extranet.intus";
import administrationDashboard from "./dashboard/administration.intus";
import supportedLocales from "./supported-locales/supported-locales.intus";

const sentryDsn = "";

const titleApp = "Intus Plus";
const name = "Intus";
const longName = "Intus";
const extranetAddresseeName = "Intus Plus Team";

let headOffice = "Wulvern House, Electra Way, Crewe, CW1 6GW. <br />Registered in England: company number 05269182";

if (__TARGET__ === "extranet") {
  headOffice += "<br />Phone: 0800 024 8050 - Email: intusplus@intushealthcare.com";
}

export default {
  ...commonSettings,
  administrationDashboard,
  administrationFooter,
  administrationMenu,
  extranetFooter,
  extranetMenu,
  headOffice,
  longName,
  name,
  sentryDsn,
  supportedLocales,
  titleApp,
  extranetAddresseeName
};
