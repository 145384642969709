import LogoAppSmall from "@ui/components/icons/LogoAppSmall";
import * as icons from "@ui/components/icons";
import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const {
  patientsRights: { CREATE_PATIENT, ACCESS_PATIENT },
  messagesRights: { CREATE_MESSAGE, ACCESS_MESSAGE },
  usersRights: { CREATE_USER, GET_USER, VIEW_USER },
  productsRights: { ACCESS_PRODUCT },
  alertsTemplatesRights: { CREATE_ALERT_TEMPLATE, ACCESS_ALERT_TEMPLATE },
  alertsRights: { ACCESS_ALERT },
  menuRights: { VIEW_MENU_SEARCH_PATIENT }
} = require("@common/services/acm/rights");

/**
 * When using dynamic redirect, we must use the route's path instead of the route's name.
 * Example : `routerLink: { path: "/foo" }` instead of `routerLink: { name: "foo" }`.
 */
const menu = [
  {
    title: localize("menus.administration.link--dashboard"),
    slug: "dashboard",
    icon: LogoAppSmall,
    routerLink: { name: "dashboard" },
    disabled: false,
    isHome: true
  },
  {
    title: localize("menus.administration.patients-medecins.title"),
    slug: "patients-medecins",
    icon: icons.iconUserClassicOutlined,
    disabled: false,
    items: [
      {
        title: localize("menus.administration.patients-medecins.patients.title"),
        slug: "patients",
        links: [
          {
            icon: icons.iconSearch,
            title: localize("menus.administration.patients-medecins.patients.link--patientSearch"),
            routerLink: {
              name: "patientSearch"
            },
            rights: [ACCESS_PATIENT, VIEW_MENU_SEARCH_PATIENT]
          },
          {
            icon: icons.iconAddPatientOutlined,
            title: localize("menus.administration.patients-medecins.patients.link--patientAddSearch"),
            routerLink: {
              name: "patientAddSearch"
            },
            rights: [CREATE_PATIENT]
          },
          {
            icon: icons.iconPatientOutlined,
            title: localize("menus.administration.patients-medecins.patients.link--patientsList"),
            routerLink: {
              name: "patients",
              alias: "patientsList"
            },
            rights: [ACCESS_PATIENT]
          }
        ]
      }
    ]
  },
  {
    title: localize("menus.administration.products.title"),
    slug: "products",
    icon: icons.iconMedicalDevice,
    disabled: false,
    items: [
      {
        title: localize("menus.administration.products.products.title"),
        slug: "all-products",
        links: [
          {
            icon: icons.iconMedicalDevice,
            title: localize("menus.administration.products.products.link--productsList"),
            routerLink: {
              name: "products",
              alias: "productsList"
            },
            rights: [ACCESS_PRODUCT]
          }
        ]
      }
    ]
  },
  {
    title: localize("menus.administration.message.title"),
    slug: "messages",
    icon: icons.iconDiscussion,
    notificationName: "countUnreadConversation",
    disabled: false,
    items: [
      {
        title: localize("menus.administration.message.messages.title"),
        slug: "messages-sub",
        links: [
          {
            icon: icons.iconAddMessage,
            title: localize("menus.administration.message.messages.link--conversationsAdd"),
            routerLink: {
              name: "conversationsAdd"
            },
            rights: [CREATE_MESSAGE]
          },
          {
            icon: icons.iconDiscussion,
            notificationName: "countUnreadConversation",
            title: localize("menus.administration.message.messages.link--conversationsList"),
            slug: "messages",
            routerLink: {
              name: "conversationsList"
            },
            rights: [ACCESS_MESSAGE]
          }
        ]
      }
    ]
  },
  {
    title: localize("menus.administration.alert.title"),
    slug: "alerts",
    icon: icons.iconAlert,
    disabled: false,
    items: [
      {
        title: localize("menus.administration.alert.alerts.title"),
        slug: "alerts-sub",
        links: [
          {
            icon: icons.iconDashboard,
            title: localize("menus.administration.alert.alerts.link--alertsDashboard"),
            routerLink: {
              name: "alertsDashboard"
            },
            rights: [ACCESS_ALERT]
          },
          {
            icon: icons.iconAlertAdd,
            title: localize("menus.administration.alert.alerts.link--alertTemplatesAdd"),
            routerLink: {
              name: "alertTemplatesAdd"
            },
            rights: [CREATE_ALERT_TEMPLATE]
          },
          {
            icon: icons.iconAlert,
            title: localize("menus.administration.alert.alerts.link--alertTemplatesList"),
            routerLink: {
              name: "alertTemplatesList"
            },
            rights: [ACCESS_ALERT_TEMPLATE]
          },
          {
            icon: icons.iconMailOutlined,
            title: localize("menus.administration.alert.alerts.link--alertsReminders"),
            routerLink: {
              name: "alertsReminders"
            },
            rights: [ACCESS_ALERT]
          }
        ]
      }
    ]
  },
  {
    title: localize("menus.administration.settings.title"),
    slug: "settings",
    icon: icons.iconSettings,
    notificationName: "countUnreadSupportTickets",
    disabled: false,
    rights: [VIEW_USER],
    items: [
      {
        title: localize("menus.administration.settings.users.title"),
        slug: "utilisateurs",
        links: [
          {
            icon: icons.iconAddUserClassicOutlined,
            title: localize("menus.administration.settings.users.link--usersSearch"),
            routerLink: {
              name: "usersSearch",
              alias: "usersSearch"
            },
            rights: [CREATE_USER]
          },
          {
            icon: icons.iconUserClassicOutlined,
            title: localize("menus.administration.settings.users.link--usersList"),
            routerLink: {
              name: "users",
              alias: "usersList"
            },
            rights: [GET_USER]
          }
        ]
      }
    ]
  }
];

export default menu;
