module.exports = {
  externalService: "native",
  customIdGeneration: "incremental",
  defaultDivisionId: "R",
  handledDivisionIds: ["R"],
  overridedModules: [
    { key: "PatientAddForm", value: "PatientAddFormIntus" },
    { key: "PatientEditContract", value: "PatientEditContractIntus" },
    { key: "PatientEditInformations", value: "PatientEditInformationsIntus" },
    { key: "PatientView", value: "PatientViewIntus" },
    { key: "PatientViewRespiratory", value: "PatientViewRespiratoryIntus" },
    { key: "PatientViewInformations", value: "PatientViewInformationsIntus" },
    { key: "PatientsListAdmin", value: "PatientsListAdminIntus" },
    { key: "IndexDashboardAdmin", value: "IndexDashboardAdminIntus" }
  ],
  overridedSchemas: [
    { key: "patient", value: "patientIntus" },
    { key: "contractAdd", value: "contractAddIntus" },
    { key: "contractEdit", value: "contractEditIntus" },
    { key: "contractAdministrativeFiles", value: "contractAdministrativeFilesIntus" }
  ],
  nameFormatInEmail: {
    toPatientTargetName: "firstname",
    toPatientSenderName: "firstname",
    toAdminTargetName: "firstname",
    toAdminSenderName: "fullname"
  }
};
